html {
    min-height: 100%;
}

body {
    margin: 0;
    padding: 0;
    background: #ededed;
}

.card {
    box-shadow: 2px 2px 2px #9f9f9f9f!important;
}

.sign-form {
    padding: 5%;
    background: white;
    max-width: 30em;
    margin: 6% auto;
    border-radius: 15px;
    box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.4);
    -o-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.4);
    -ms-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.4);
    font-family: Raleway, sans-serif;
}

.form button, .form h5 {
    margin: 20px 0;
}

input[type=text]:not(.browser-default):focus:not([readonly]),
input[type=email]:not(.browser-default):focus:not([readonly]),
input[type=password]:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]){
    border-color: #43A047;
    box-shadow: none;
}

input[type=text]:not(.browser-default):focus:not([readonly]) + label,
textarea.materialize-textarea:focus:not([readonly]) + label,
input[type=email]:not(.browser-default):focus:not([readonly]) + label,
input[type=password]:not(.browser-default):focus:not([readonly]) + label{
    color: #43A047 !important;
}

.login-form-btn {
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    color: #fff;
    line-height: 1.2;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    height: 50px;
    background-color: #43A047;
}

.registrer-form-btn {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    color: #000;
    line-height: 1.2;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    height: 50px;
    text-decoration: underline;
}

/*------------------PAGE PROFILE------------------*/

.section {
    padding: 3rem 1.5rem;
}

.account-header .account-title {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 20px;
}

.account-header .account-title h2 {
    width: 100%;
}

.account-header h2 {
    font-family: 'Raleway', sans-serif;
    font-size: 30px;
    font-weight: 400 !important;
    text-transform: uppercase;
}

.account-header .account-title .brand-filigrane {
    position: absolute;
    top: 10px;
    left: -40px;
    height: 80px;
    opacity: 0.4;
}

.account-tabs {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    -webkit-overflow-scrolling: touch;
    -ms-flex-align: stretch;
    align-items: stretch;
    display: -ms-flexbox;
    display: flex;
    font-size: 1rem;
    -ms-flex-pack: justify;
    justify-content: space-between;
    overflow: hidden;
    overflow-x: auto;
    white-space: nowrap;
}

.account-tabs ul {
    -ms-flex-align: center;
    align-items: center;
    border-bottom-color: #dbdbdb;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.account-tabs li {
    display: block;
}

.account-tabs a {
    -ms-flex-align: center;
    align-items: center;
    border-bottom-color: #dbdbdb;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    color: #4a4a4a;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: -1px;
    padding: 0.5em 1.3em;
    vertical-align: top;
    font-size: 16px;
}

.account-tabs li a {
    color: #6b6b6b;
    border-width: 2px;
    padding-bottom: 15px;
}

.account-tabs li.is-active a {
    border-bottom-color: #39943d;
    color: #1e7522;
}

.flat-card {
    position: relative;
    background-color: #fff;
    border: 1px solid #ededed;
    border-radius: 0.1875rem;
    display: inline-block;
    position: relative;
    overflow: hidden;
    width: 100%;
    transition: all 0.5s;
    max-height: 270px;
    min-height: 270px;
}

.profile-card {
    border: none;
    margin-bottom: 20px;
}

.flat-card.is-auto {
    height: auto !important;
    width: 100% !important;
    min-height: 0 !important;
    max-height: none !important;
    max-width: auto !important;
    box-shadow: 0px 5px 43px rgba(0,0,0,0.04) !important;
}

.flat-card:hover {
    box-shadow: 0px 5px 43px rgba(0,0,0,0.2) !important;
}

.profile-card .card-body {
    padding: 20px 40px;
}

.profile-card .profile-image img {
    display: block;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin: 0 auto;
}

.profile-card .username {
    padding-top: 10px;
}

.has-text-centered {
    text-align: center !important;
}

.profile-card .username span {
    color: #4a4a4a;
    font-weight: 500;
    font-size: 18px;
}

.profile-card .username span, .profile-card .username small {
    display: block;
}

.profile-card .username span, .profile-card .username small {
    display: block;
}

.profile-card .profile-footer {
    padding: 20px 40px;
    background: #fcfcfc;
}

.profile-card .profile-footer .achievement-title {
    font-family: 'Raleway', sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    font-size: .75rem;
    color: #6b6b6b;
}

.profile-card .profile-footer .count {
    font-size: 1.5rem;
    font-weight: 700;
    color: #4a4a4a;
}

.profile-info-card, .menu-card {
    margin-bottom: 20px;
    position: relative;
}

.profile-info-card .card-title, .menu-card .card-title {
    position: relative;
    padding: 15px 20px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    background: #fcfcfc;
    z-index: 2;
}

.profile-info-card .card-title h3, .menu-card .card-title h3 {
    font-weight: 500;
    color: #828282;
}

.profile-info-card .card-title .edit-account, .menu-card .card-title .edit-account {
    position: relative;
    height: 40px;
    width: 40px;
    max-height: 40px;
}

.profile-info-card .card-title .edit-account a, .menu-card .card-title .edit-account a {
    color: grey;
    height: 40px;
    width: 40px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    border: 1.8px dashed #6b6b6b;
    border-radius: 50%;
    max-height: 40px;
    transition: all .2s;
}

.profile-info-card .card-title .edit-account a:hover, .menu-card .card-title .edit-account a:hover {
    border: 1.8px solid #0023ff;
    color: #0023ff;
}

.profile-info-card .card-title .edit-account svg, .menu-card .card-title .edit-account svg {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    stroke-width: 1.2px;
}

.profile-info-card .card-body, .menu-card .card-body {
    padding-top: 30px;
    padding-right: 20px;
    padding-left: 20px;
}

.profile-info-card .card-body .info-block, .menu-card .card-body .info-block {
    margin-bottom: 20px;
}

.profile-info-card .card-body .info-block span.label-text, .menu-card .card-body .info-block span.label-text {
    color: #4a4a4a;
    font-weight: 500;
    font-size: 16px;
}

.profile-info-card .card-body .info-block span.label-value, .menu-card .card-body .info-block span.label-value {
    color: #828282;
}

.profile-info-card .card-body .info-block span, .menu-card .card-body .info-block span {
    display: block;
}

/*------------------COMMANDES------------------*/

.order-card {
    position: relative;
    padding: 20px;
    margin-bottom: 20px;
    overflow: visible;
}

.order-card .order-info {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
    padding-bottom: 20px;
}

.order-card .order-info span {
    display: block;
}

.order-card .order-info span a {
    color: #4a4a4a;
    font-size: 90%;
    font-weight: 500;
}

.tag:not(body) {
    -ms-flex-align: center;
    align-items: center;
    background-color: #f5f5f5;
    border-radius: 4px;
    color: #4a4a4a;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-size: .75rem;
    height: 2em;
    -ms-flex-pack: center;
    justify-content: center;
    line-height: 1.5;
    padding-left: 0.75em;
    padding-right: 0.75em;
    white-space: nowrap;
}

.order-card .tag {
    position: relative;
    display: -ms-flexbox !important;
    display: flex !important;
}

.order-card .tag.is-primary {
    background: #0023ff !important;
    color: #fff !important;
}

.order-card .tag.is-success {
    background: #00b289 !important;
    color: #fff !important;
}

.order-card .tag.is-warning {
    background: #eda514 !important;
    color: #fff !important;
}

.order-card .tag.is-danger {
    background: #FF7273 !important;
    color: #fff !important;
}

.order-card .circle-chart-wrapper {
    text-align: center;
}

.order-card .chart-icon {
    position: absolute;
    top: 45%;
    left: 0;
    width: 100%;
    height: 40px;
}

.order-card .chart-icon svg {
    position: relative;
    width: 30px;
    height: 30px;
    stroke: #6b6b6b;
}

.order-card .ring-title span {
    font-size: 1rem;
    color: #A9ABAC;
}

.order-card .circle-chart-circle {
    -webkit-animation: circle-chart-fill 2s reverse;
    animation: circle-chart-fill 2s reverse;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: center;
    transform-origin: center;
}

.order-card .circle-chart {
    margin-bottom: 10px;
}

/*------------------EDIT ACCOUNT------------------*/

.control {
    font-size: 1rem;
    position: relative;
    text-align: left;
}

.input.is-default {
    box-shadow: none;
    color: #828282;
}